import { combineReducers } from 'redux';
import {
    NFT_LIST_FETCH_SUCCESS,
    NFT_LIST_FETCH_ERROR,
    NFT_LIST_FETCH_IN_PROGRESS,
    NFT_SELECT_SET,

    STAKE_CARDS_LIST_SET,
    STAKE_CARD_SET,

    DISCARD_CARDS_LIST_SET,
    DISCARD_CARD_SET,
} from '../constants/home';

const nftList = (state = {
    inProgress: false,
    result: [],
    count: null,
}, action) => {
    switch (action.type) {
    case NFT_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case NFT_LIST_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.result,
        };
    case NFT_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const selectedNftList = (state = {
    value: [],
}, action) => {
    switch (action.type) {
    case NFT_SELECT_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const stakeCardsList = (state = {
    value: [],
}, action) => {
    switch (action.type) {
    case STAKE_CARDS_LIST_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const selectedStakeCard = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case STAKE_CARD_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const discardCardsList = (state = {
    value: [],
}, action) => {
    switch (action.type) {
    case DISCARD_CARDS_LIST_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const selectedDiscardCard = (state = {
    value: [],
}, action) => {
    switch (action.type) {
    case DISCARD_CARD_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

export default combineReducers({
    nftList,
    selectedNftList,

    stakeCardsList,
    selectedStakeCard,

    discardCardsList,
    selectedDiscardCard,
});
