export const NFT_LIST_FETCH_IN_PROGRESS = 'NFT_LIST_FETCH_IN_PROGRESS';
export const NFT_LIST_FETCH_SUCCESS = 'NFT_LIST_FETCH_SUCCESS';
export const NFT_LIST_FETCH_ERROR = 'NFT_LIST_FETCH_ERROR';

export const NFT_SELECT_SET = 'NFT_SELECT_SET';

export const STAKE_CARDS_LIST_SET = 'STAKE_CARD_LIST_SET';
export const STAKE_CARD_SET = 'STAKE_CARD_SET';

export const DISCARD_CARDS_LIST_SET = 'DISCARD_CARD_LIST_SET';
export const DISCARD_CARD_SET = 'DISCARD_CARD_SET';
