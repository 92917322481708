import { combineReducers } from 'redux';
import language from './language';
import snackbar from './snackbar';
import home from './home';

export default combineReducers({
    language,
    snackbar,
    home,
});
